import { createContext, useState } from "react";
import contactImginsta from '../../img/hipster-avatar-image-vector.jpg';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {

    const [numInp, setNumInp] = useState();
    const [auth, setAuth] = useState({});
    const [style, setStyle] = useState(true);
    const [socialShow, setSocialShow] = useState(false);
    const [show, setShow] = useState(true);
    const [accShow, setAccShow] = useState(true);
    const [detShow, setDetShow] = useState(true);
    const [tableShow, setTableShow] = useState(true);
    const [socialCh, setSocailCh] = useState("");
    const [contactImg, setContactImg] = useState(contactImginsta);
    const [inpShow, setmInpShow] = useState(false);
    const [userName, setUserName] = useState();
    const [subAlert, setSubAlert] = useState(false);
    const [inqData, setInqData] = useState();
    const [whtData, setWhtData] = useState();
    const [instaMenu, setInstaMenu] = useState(false)
    const [whatsappMenu, setWhatsappMenu] = useState(false)
    const [inpKind, setInpKind] = useState("id");
    const [inq, setInq] = useState();
    const [dark, setDark] = useState(false);
    const [inqShow, setInqShow] = useState(false);
    const [accData, setAccData] = useState();
    const [instaCard, setInstaCard] = useState(false);

    return (
        <AuthContext.Provider value={{ instaCard, setInstaCard, inqShow, setInqShow, auth, setAuth , style , setStyle , socialShow , setSocialShow, show, setShow, detShow, setDetShow, tableShow, inqData, setInqData, instaMenu, setInstaMenu, inq, setInq,
         setTableShow, socialCh, setSocailCh, contactImg, setContactImg, numInp, setNumInp, inpShow, setmInpShow, userName, setUserName, accShow, setAccShow, subAlert, setSubAlert, inpKind, setInpKind, dark, setDark, whtData, setWhtData,
         accData, setAccData, whatsappMenu, setWhatsappMenu }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;