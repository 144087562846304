import { useContext, useState, useEffect } from "react";
import axios from "./Api/axios";
import AuthContext from './Context/AuthProvider';
import contactImgTel from '../img/sample-avatar-min.jpg';
import contactImginsta from '../img/hipster-avatar-image-vector.jpg';
import contactImgTw from '../img/contact.svg';
import contactImgFa from '../img/hipster-avatar-image-vector.jpg';
import { faCheckSquare, faQuestion, faQuestionCircle, faXmarkSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faTelegram, faTwitter, faWhatsapp } from "@fortawesome/free-brands-svg-icons";

function InqSin() {

    const { setDetShow, setSocailCh, setContactImg, numInp, inqData, inpKind, setInqData, inq, setInq, subAlert} = useContext(AuthContext);
    // const [inq, setInq] = useState();
    const [stat, setStat] = useState();
    const [input, setInput] = useState(null);
    const [loading, setLoading] = useState(false);
    const [reqCode, setReqCode] = useState();

    useEffect(() => {
        setInput(numInp);
        let isMounted = true;
        const controller = new AbortController();

        const getList = async () => {
            try {
                setLoading(true);
                const response = await axios.get(`/check/${inpKind}/${numInp}`, {
                    signal: controller.signal
                });
                isMounted && setInqData(response?.data);
                setInq(response?.data?.data[0]);
                setLoading(false);
                console.log(response);
            } catch (err) {
                console.error(err);
                // navigate('/sign-in', { state: { from: location }, replace: true });
            }
        }

        getList();

        const MySubmit = async () => {
            // e.preventDefault();
            try {
                // setSubAlert(false);
                const res = await axios.post(`/add/${inpKind}`, { input });
                setReqCode(inqData?.code);
                // setResCode(res?.data?.code);
                // setSubAlert(true);
                // console.log(typeof(input));
                console.log(res);
                // sinInputRef.current.value = '';
                // setInput('');
            } catch (err) {
                console.error(err);
                // navigate('/sign-in', { state: { from: location }, replace: true });
            }
        };

        inqData?.code == 300 && MySubmit();
        inqData?.code == 200 && setReqCode(200);
        
        return () => {
            isMounted = false;
            isMounted && controller.abort();
        }
    }, [numInp, input, reqCode]);

    console.log(inqData?.code);
    console.log(reqCode);

    const reportingDetails = () => {
        setDetShow(false);
    };
    return (
        <>
            <p className=' px-8'>
                <span className="">برای مشاهده جزئیات هر اکانت بر روی گزینه مورد نظر کلیک نمایید.</span>
                {
                    reqCode == 300 ? <span className=" bg-green-200 p-2 w-fit mx-auto text-center text-sm rounded mt-8 transition-all duration-200 ease-in select-none" style={subAlert ? { "visibility": "visible", "opacity": 1 } : { "visibility": "hidden", "opacity": 0 }}>ورودی از قبل در سیستم وجود نداشت و اضافه شد.</span> : ''
                }
            </p>
            <div className="flex lg:w-1/2 mx-auto mt-4 flex-col justify-center bg-gray-200 shadow-sm shadow-middle-green rounded">
                <div className="flex justify-between items-center bg-middle-green text-white border-b border-b-gray-400 rounded-t p-2">
                    <div className="flex items-center"><span className="">شماره: {numInp}</span></div>
                </div>
                {/* <div onClick={() => { reportingDetails(); setSocailCh("telegram"); setContactImg(contactImgTel) }} className="flex justify-between items-center border-b border-b-gray-400 p-1 cursor-pointer hover:bg-gray-300 transition-all duration-100 ease-in">
                    <div className="flex items-center mr-4"><span className='inline-block place-content-center align-top text-blue-600 text-lg ml-2'><FontAwesomeIcon icon={faTelegram} /></span><span className="">تلگرام</span></div>
                    <div className="flex items-center w-16 justify-center"><span className='inline-block place-content-center align-top text-red-500 text-lg ml-2'><FontAwesomeIcon icon={faXmarkSquare} /></span></div>
                </div>
                <div onClick={() => { reportingDetails(); setSocailCh("whatsapp"); setContactImg(contactImgTw) }} className="flex justify-between items-center border-b border-b-gray-400 p-1 cursor-pointer hover:bg-gray-300 transition-all duration-100 ease-in">
                    <div className="flex items-center mr-4"><span className='inline-block place-content-center align-top text-green-800 text-lg ml-2'><FontAwesomeIcon icon={faWhatsapp} /></span><span className="">واتساپ</span></div>
                    <div className="flex items-center w-16 justify-center"><span className='inline-block place-content-center align-top text-red-500 text-lg ml-2'><FontAwesomeIcon icon={faXmarkSquare} /></span></div>
                </div> */}
                <div onClick={() => { reportingDetails(); setSocailCh("insta"); setContactImg(contactImginsta) }} className="flex justify-between items-center border-b border-b-gray-400 p-1 cursor-pointer hover:bg-gray-300 transition-all duration-100 ease-in">
                    <div className="flex items-center mr-4"><span className='inline-block place-content-center align-top fill-gradient-instagram text-white text-lg ml-2'><FontAwesomeIcon icon={faInstagram} /></span><span className="">اینستاگرام</span></div>
                    <div className="flex items-center w-16 justify-center">
                        {inqData?.code == 200 && <span>
                            {inq?.status == 3 && <span className='inline-block place-content-center align-top text-green-500 text-lg ml-2'><FontAwesomeIcon icon={faCheckSquare} /></span>}
                            {inq?.status == 2 && <span className='inline-block place-content-center align-top text-red-500 text-lg ml-2'><FontAwesomeIcon icon={faXmarkSquare} /></span>}
                            {inq?.status == 1 && <span className='inline-block place-content-center align-top text-blue-500 text-base w-40 ml-2'>در انتظار استعلام</span>}
                            {inq?.status == 0 && <span className='inline-block place-content-center align-top text-gray-500 text-base w-40 ml-2'>در انتظار بررسی</span>}
                        </span>
                        }
                        {
                            inqData?.code == 300 && <span className='inline-block place-content-center align-top text-gray-500 text-lg ml-2'><FontAwesomeIcon icon={faQuestionCircle} /></span>
                        }
                    </div>
                </div>
                {/* <div onClick={() => { reportingDetails(); setSocailCh("twitter"); setContactImg(contactImgTw) }} className="flex justify-between items-center border-b border-b-gray-400 p-1 cursor-pointer hover:bg-gray-300 transition-all duration-100 ease-in">
                    <div className="flex items-center mr-4"><span className='inline-block place-content-center align-top text-blue-600 text-lg ml-2'><FontAwesomeIcon icon={faTwitter} /></span><span className="">توییتر</span></div>
                    <div className="flex items-center w-16 justify-center"><span className='inline-block place-content-center align-top text-red-500 text-lg ml-2'><FontAwesomeIcon icon={faXmarkSquare} /></span></div>
                </div>
                <div onClick={() => { reportingDetails(); setSocailCh("fb"); setContactImg(contactImgFa) }} className="flex justify-between items-center p-1 cursor-pointer hover:bg-gray-300 transition-all duration-100 ease-in">
                    <div className="flex items-center mr-4"><span className='inline-block place-content-center align-top text-blue-600 text-lg ml-2'><FontAwesomeIcon icon={faFacebook} /></span><span className="">فیسبوک</span></div>
                    <div className="flex items-center w-16 justify-center"><span className='inline-block place-content-center align-top text-red-500 text-lg ml-2'><FontAwesomeIcon icon={faXmarkSquare} /></span></div>
                </div> */}
            </div>
        </>
    )

}

export default InqSin
